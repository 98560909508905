.forgot-password {
  background: #4184f3;
  height: 100%;
  min-height: 100vh;
}

.send-req-card {
  margin-top: 15%;
  color: #fff;

  input::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #fff;
    opacity: 1; /* Firefox */
  }

  input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #fff;
  }

  input::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #fff;
  }
}

.send-txt {
  margin-top: 30px;
}

.send-txt p {
  margin-top: 20px;
}

section.forgot-password .form-control {
  background: transparent;
  border: none;
  color: #fff;
  font-size: 15px;
  border-radius: 0px;
  padding: 6px 40px;
  margin: auto;
  border-bottom: 1px solid;
}

section.forgot-password .form-group {
  color: #fff;
  width: 60%;
  padding-bottom: 25px;
  margin: auto;
  position: relative;
}

.input-icon {
  position: absolute;
  left: 9px;
  font-size: 20px;
  color: #ffffff;
  line-height: 40px;
}

.send-img img {
  width: 40%;
}

.send-btn {
  background: #3373dd;
  border: none;
  color: #fff;
  height: 40px;
  min-width: 250px;
  box-shadow: 0px 0px 2px 0px #3373dd;
}
