.login-container {
  padding: 45px;
  min-height: 100%;
  width: 100%;
  // height: 100%;
  background-color: #8bb7ff;
}
.forget-pass {
  color: #fff;
  font-size: 16px;
  margin-bottom: 15px;
  text-align: right;
  display: inline-block;
  text-decoration: none;

  &:hover {
    color: #fff;
  }
}
.login-card {
  margin: auto;
  background: #3373dd;
  color: #fff;
  margin-top: 2%;
  min-height: 600px;
  padding: 40px;
  border-radius: 20px;
  box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 10%);
}
.login-card input {
  padding: 0.5rem 2rem !important;
}
.login-card .form-group {
  position: relative;
  margin-bottom: 20px;
}
.login-card .input-icon {
  position: absolute;
  left: 7px;
  font-size: 20px;
  color: #707070;
  margin-top: 2px;
}
button.login-btn {
  width: 100%;
  height: 40px;
  border: none;
  background: #1149a3;
  color: #fff;
}
.login-logo {
  margin: auto;
  width: 130px;
  height: 130px;
  background: #fff;
  line-height: 100px;
  padding: 12px;
  margin-bottom: 20px;
  border-radius: 50%;
  box-shadow: 0px 0px 8px 8px #dfdede;
}
.login-card .input-icon-right {
  position: absolute;
  right: 9px;
  font-size: 20px;
  color: #707070;
  line-height: 40px;
}

.login-card {
  .form-group {
    label {
      margin-bottom: 0.5rem;
    }
  }
}

.forgot-password-link {
  text-align: right;
}
