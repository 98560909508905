.select-box {
  position:relative;
  width:100%;
  margin-bottom: 10px;
}

.select-box select {
  color: #0563af;
  padding: 5px;
  width: 100%;
  border: 1px solid #4184f3;
  font-size: 13px;
  background: #4184f32b;
  appearance: button;
  border-radius: 5px;
  height: 30px;
  outline: none;
  min-height: 30px;
}

// .select-box::before {
//  content: "\f107";
//  font-family: FontAwesome;
//  position: absolute;
//  border-radius: 0px 5px 5px 0px;
//  top: 0;
//  right: 0;
//  width: 35px;
//  height: 100%;
//  text-align: center;
//  font-size: 20px;
//  line-height: 35px;
//  color: #fff;
//  background-color: #8ba6d2;
//  pointer-events: none;
// }
// .select-box:hover::before{
//   background: #4184f3;
// }

.select-box select option {
  padding: 30px;
}
.input-box{
    color:  #0563af!important;
    margin-bottom: 10px;
}
.input-box input {
    height: 30px;
    padding: 5px 15px;
    font-size: 13px;
  width: 100%;
  border:1px solid #4184f3!important;
    color:  #0563af!important;
}
.custom-switch .custom-control-label::before {
  left: -2rem;
  height: 22px;
  border-radius: 31px !important;
  width: 3.5rem;
  pointer-events: all;
  border-radius: 0.5rem;
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #fff;
    -webkit-transform: translateX(0.75rem);
    transform: translateX(1.8rem);
}
.custom-switch .custom-control-label::after {
  top: calc(0.3rem + 2px);
  left: calc(-2.7rem + 15px);
  width: calc(1rem + 1px);
  height: 1.1em;
  border-radius: 20px;
}
.custom-control-label {
    position: relative;
    font-size: 13px;
    margin-bottom: 0;
    padding-left:45px;
    padding-top: 5px;
    vertical-align: top;
}
.same-active {
  border: 1px solid #4184f3;
  min-width: 300px;
  text-align: center;
  margin-left: 16px;
  padding: 5px;
}
.impred{
  color: red;
}
.input-box textarea{
  border:1px solid #4184f3!important;
}
.date-box{
position:relative;
width:100%;
margin-bottom: 10px;
}
// .date-box::before {
// content: "\f073";
// font-family: FontAwesome;
// position: absolute;
// border-radius:0px 5px 5px 0px;
// top: 0;
// right: 0;
// width: 8%;
// height: 100%;
// text-align: center;
// font-size: 20px;
// line-height: 30px;
// color:  #fff;
// background-color:#8ba6d2;
// pointer-events: none;
// }
// .date-box:hover::before{
// background: #4184f3;
// }
.date-box input {
color:  #0563af;
padding: 10px;
height: 30px;
width: 100%;
border:1px solid #4184f3;
font-size: 13px;
-webkit-appearance: button;
appearance: button;
border-radius:5px;
outline: none;
min-height: 30px;
}
/* .detail-view {
border: 1px solid #007bff;
}*/
.detail-view h5 {
font-weight: 500;
font-size: 17px;
}
.detail-view p{
  padding: 5px 15px;
  min-height: 30px;
  font-size: 13px;
  border: 1px solid #007bff;
  word-break: break-word;
}
.dash-box {
position: relative;
width: 100%;
margin-bottom: 15px;
}
// .dash-box::before {
// content: "\f009";
// font-family: FontAwesome;
// position: absolute;
// border-radius: 0px 5px 5px 0px;
// top: 0;
// right: 0;
// width: 8%;
// height: 100%;
// text-align: center;
// font-size: 20px;
// line-height: 45px;
// color: #fff;
// background-color: #8ba6d2;
// pointer-events: none;
// }
// .dash-box:hover::before{
// background: #4184f3;
// }
// .date-box:hover::before{
// background: #4184f3;
// }
.dash-box input {
color: #0563af;
padding: 10px;
width: 100%;
border: 1px solid #4184f3;
font-size: 16px;
-webkit-appearance: button;
appearance: button;
border-radius: 5px;
outline: none;
min-height: 45px;
}
.menu-box {
position: relative;
width: 100%;
margin-bottom: 15px;
}
// .menu-box::before {
// content: "\f142";
// font-family: FontAwesome;
// position: absolute;
// border-radius: 0px 5px 5px 0px;
// top: 0;
// right: 0;
// width: 8%;
// height: 100%;
// text-align: center;
// font-size: 20px;
// line-height: 45px;
// color: #fff;
// background-color: #8ba6d2;
// pointer-events: none;
// }
// .menu-box:hover::before{
// background: #4184f3;
// }
.menu-box input {
color: #0563af;
padding: 10px;
width: 100%;
border: 1px solid #4184f3;
font-size: 16px;
-webkit-appearance: button;
appearance: button;
border-radius: 5px;
outline: none;
min-height: 45px;
}
.codes-row{
margin-top: 90px;
}
.single-certificate {
display: flex;
width: 100%;
}
.certi-name {
width: 20%;
text-align: center;
}
.certi-box {
width: 25%;
text-align: center;
}
.expiry-date {
width: 25%;
text-align: center;
}
.cert-btn {
width: 25%;
max-width: 150px;
}
.cert-btn button {
min-width: 160px;
height: 40px;
}
.certi-margin{
border: 1px solid #4184f3;
margin: 0px 10px;
line-height: 35px;
}
.certificate-item{
margin-top: 15px;
}
.single-certi h6 {
min-height: 20px;
}
.single-certi p {
border: 1px solid #4184f3;
text-align: center;
padding: 10px;
}
.single-certi button {
min-width: 200px;
min-height: 30px;
line-height: 30px;
}
.single-certi{
text-align: center;
}
.serialized{
border:1px solid #dbd8d8;
}
.serialized-nav{
width: 100%;
}
.serialized-nav li{
width: 50%;
height: 50px;
background:#a6b3c9;
line-height: 50px;
text-align: center;
}
.serialized-nav li a{
font-weight:600;
font-size:20px;
width:100%;
text-decoration:none;
color:#fff!important;
}
.serialized-nav li.active{
background:#4184f3;

}
.serialized .tab-content{
padding: 30px;
}
.dots-box:before{
content: "\f142";
font-family: FontAwesome;
position: absolute;
border-radius: 0px 5px 5px 0px;
top: 0;
right: 0;
width: 45px;
height: 100%;
text-align: center;
font-size: 20px;
line-height: 45px;
color: #fff;
background-color: #8ba6d2;
pointer-events: none;
}
.dots-box:hover::before{
 background: #4184f3;
}
.dots-box input {
color: #0563af;
padding: 10px;
width: 100%;
border: 1px solid #4184f3;
font-size: 16px;
appearance: button;
border-radius: 5px;
outline: none;
min-height: 45px;
}
.dots-box {
position: relative;
width: 100%;
margin-bottom: 15px;
}