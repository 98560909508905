* {
  font-family: 'Poppins-regular', sans-serif;
}

html,
body,
#root,
.main-wrapper {
  height: 100%;
  width: 100%;
}

.form-check-label {
  white-space: nowrap;
}

.main-wrapper {
  grid-area: main;
  position: relative;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    "sidebar header"
    "sidebar main";
}

.main-wrapper.rtl {
  grid-template-columns: 1fr auto;
  grid-template-rows: 1fr auto;
  grid-template-areas:
    "header sidebar"
    "main sidebar";

  .main-container {
    // box-shadow: inset 0px 2px 10px 0 rgba($dark, 0.55);
  }
}

.main-wrapper-responsive {
  @each $breakpoint in map-keys($grid-breakpoints) {
    $next: breakpoint-next($breakpoint, $grid-breakpoints);
    $infix: breakpoint-infix($next, $grid-breakpoints);

    &.sidebar-menu-rtl {
      grid-template-areas:
        "header sidebar"
        "main sidebar";
    }

    // stylelint-disable-next-line scss/selector-no-union-class-name
    &#{$infix} {
      @include media-breakpoint-down($next) {
        // grid-template-areas:
        //   "header header"
        //   "sidebar main";

        // &.rtl {
        //   grid-template-areas:
        //     "header header"
        //     "main sidebar";
        // }
      }

      &.rtl {
        .sidebar-menu-header {
          flex-direction: row-reverse;
        }
      }
    }
  }
}

.main-container {
  grid-area: main;
  // box-shadow: inset 2px 1px 9px 1px rgba($dark, 0.10);
  overflow: auto;
  padding-top: 1rem;
  background: #eff3f6;
}