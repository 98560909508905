// .login-container {
//     padding: 45px;
//     background-color: #8bb7ff;
//     height: 100vh;
// }

.forget-pass {
    color: #fff;
    font-size: 16px;
    margin-top: 15px;
    text-align: right;
    display: inline-block;
}

.login-card {
    margin: auto;
    background: #3373dd;
    color: #fff;
    margin-top: 4%;
    min-height: 600px;
    padding: 50px 50px;
    box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 10%);
}

.login-card input {
    padding: 0.5rem 2rem !important;
}

.login-card .form-group {
    position: relative;
    margin-bottom: 20px;
}

.login-card .input-icon {
    position: absolute;
    left: 9px;
    font-size: 20px;
    color: #707070;
    line-height: 40px;
}

.login-btn {
    padding: 8px 12px;
    border-radius: 5px;
    border: none;
    background: #8ba6d2;
    color: #fff;
}

.login-logo {
    margin: auto;
    width: 130px;
    height: 130px;
    background: #fff;
    line-height: 100px;
    padding: 12px;
    margin-bottom: 20px;
    border-radius: 50%;
}

.login-card .input-icon-right {
    position: absolute;
    right: 9px;
    font-size: 20px;
    color: #707070;
    line-height: 40px;
}

// .login-btn i {
//     font-size: 12px;
//     width: 14px;
//     text-align: center;
//     height: 14px;
//     line-height: 14px;
//     background: #fff;
//     color: #626262;
//     border-radius: 50%;
// }

.nav.reset-nav {
    float: right;
    margin-top: 0px;
}

.reset-nav .nav-item {
    margin-left: 10px;
}

:focus-visible {
    outline: none !important;
}

.reset-nav .login-btn.active, .reset-nav .login-btn:hover {
    background: #1149a3;
    color: #fff;
}

.user-details {
    text-align: center;
    font-size: 20px;
    margin-bottom: 30px;
}

body {
    background: #8bb7ff;
}
