body{
    background:#eff3f6;
    }
    .dashboard {
        background: #fff;
        padding: 10px;
    }
    thead {
        background: #3373dd;
        color: #fff;
    }
    .local-table th,td {
        font-size: 14px;
        font-weight: 200!important;
    }
    .bg-secondary:hover, a.bg-secondary:focus, button.bg-secondary:hover, button.bg-secondary:focus {
        background-color: #0056b3 !important;
    }
    .dataTables_wrapper .dataTables_paginate .paginate_button.current, .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover{
        background-color: #0056b3 !important;
        color: #fff!important;
    }
    .dataTables_wrapper .dataTables_paginate .paginate_button:hover{
        background-color: #0056b3 !important;
        color: #fff!important;
    }
    .table-heading {
        margin-bottom: 1px;
        color: #3e4449;
        display: flex;
    }
   
.addbutton {
    box-shadow: 0 9px 20px 0 rgb(0 0 0 / 17%), 0 6px 23px 0 rgb(0 0 0 / 10%);
    height: 35px;
    padding: 0px 20px;
    min-width: 80px;
    line-height: 35px;
    border-radius: 6px;
    border: none;
    color: #fff;
    background: #3373dd;
    font-size: 13px;
}
    :focus-visible {
        outline: none!important;
    }
    button:focus {
        outline: none!important;
    }
    .dataTables_wrapper .dataTables_length select{
        color: #0056b3 !important;
    
    }
    .logo-lg img{
        height: 70px;
    }
    .sidebar .logo-lg{
    display: block;
    width: auto;
    
    text-align: center;
    }
    .sidebar.close .logo-sm{
        display: block;
    }
    .home-icon {
        width: 58px;
        height: 58px;
        line-height: 70px;
        background: rgba(255, 255, 255, .15);
        border-radius: 4px;
        text-align: center;
        margin-right: 10px;
    }
    .css-190gei4{
        padding: 10px !important;
    }
    .home-card {
        background: #4184f3 !important;
        color: #fff;
        padding: 10px;
    }
    
    .home-icon i {
        font-size: 30px;
    }
    .sidebar .logo-sm{
        display: none;
        }
        .sidebar.close .logo-lg{
            display:none;
        }
        .navbar-brand-box {
            margin-top: 20px;
        }
        #example button {
            margin: 2px;
        }
        #example i {
            font-size: 17px;
        }
        .btn:hover {
            color: var(--bs-btn-hover-color);
            background-color: #3373dd;
            border-color: #3373dd;
        }
        .btn-footer button {
            margin-right: 10px;
            font-size: 13px;
        }
        .custom-switch .custom-control-label::after {
            background:#c1bdbd;
        }
        .custom-switch .custom-control-label::before {
            border: 2px solid #4184f3;
        }
        .custom-control {
            padding-left: 2.5rem!important;
        }
    
    circle.recharts-dot.recharts-line-dot {
        stroke: #3373dd !important;
        font-weight: 600 !important;
        stroke-width: 7px !important;
        fill: #ffffff !important;
        r: 5 !important;
    }
    
    table button {
        margin-right: 3px;
    }
    
    table i {
        font-size: 20px;
    }
    table#example {
        margin-bottom: 0px;
    }
    .dashboard .card-body{
        padding:0px!important;
    }
    .filter-container {
        display: flex;
        flex-flow: wrap;
        padding: 20px 0px;
        width:98%;
      }
      
      .single-filter button {
        margin: 5px;
        background: #8ba6d2;
        border-color: #8ba6d2;
        color: #fff;
        border-radius: 4px;
        border: none;
        line-height: 50px;
        width: 100%;
        min-height: 50px;
        position: relative;
        display: inline-flex;
        padding: 0px;
        font-size: 13px;
        padding-left: 12px!important;
        width: 100%;
      }
      .single-filter button p {
        padding-left: 13px;
        word-break: break-word!important;
        margin-bottom: 0px!important;
        line-height: 25px!important;
        margin-top: 12px;
        width: 75%;
    }
      
      .single-filter button span {
        min-height: 50px;
        min-width: 50px;
        position: absolute;
        right: 0px;
        border-radius: 0px 10px 10px 0px;
        float: right;
        height: 100%;
        background: #849dc6;
      }
      .single-filter {
        max-width: 23%!important;
        flex: 23%!important;
        padding: 4px;
    }
    .single-filter button:hover {
        background: #4184f3;
    }
    .single-filter button:hover span {
        background: #3373dd;
    }
    .pti-field {
        display: inline-flex;
        width: 100%;
    }
    .pti-field label {
        width: 25%;
    }
    .pti-field label {
        width: 25%;
        font-size: 15px;
        color: #605e5e;
        padding-top: 7px;
    }
    .date-box-pti:before{
        width: 45px !important;
        font-size: 16px!important;
    }
    .select-box-pti:before{
        // width: 20%!important;
        font-size: 15px!important;
    }
    .pti-detail-container label{
        margin-top: 10px;
        font-size: 13px;
        color: #8d8d8d;
        font-weight: 600;
    }
    .date-box:hover::before{
        background: red;
    }
    .single-pti-box{
            border: 1px solid #c5c6c7;
            margin-bottom: 10px;
            border-radius: 10px;
        }
    .single-pti-box-header{
        background: #3373dd;
        color: #fff;
        font-size: 14px;
        font-weight: 600;
        padding: 6px 0px;
        text-align: center;
    }
    .single-pti-box-group{
        display: inline-flex;
        width: 100%;
        margin-bottom: 0rem!important;
    }
        .single-pti-box-group label {
            // width: 50%;
            color: #787878;
            font-size: 13px;
            font-weight: 600;
            margin-top: 5px;
        }
    // .single-pti-box-group div {
    //     width: 100%;
    
    // }
    .pti-addbutton {
        min-width: 100%;
        border: none;
        color: #fff;
        font-weight: 600;
        background: #3373dd;
        font-size: 16px;
        padding: 10px 0px;
    }
    .table-bordered > :not(caption) > * {
        border-width:0!important; 
    }
    .table-responsive>.table-bordered {
        border:1px solid #dee2e6!important; 
    }
    td {
        max-width: 300px!important;
    }
    .btn-secondary{
        background:#7d8082;
    }
    
    
    
    .menu-container {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      
      .menu-trigger {
        border-radius: 90px;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 4px 6px;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
        border: none;
        vertical-align: middle;
        transition: box-shadow 0.4s ease;
        margin-left: auto; /* Strictly for positioning */
      }
      
      .menu-trigger:hover {
        box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
      }
      
      .menu-trigger span {
        font-weight: 700;
        vertical-align: middle;
        font-size: 14px;
        margin: 0 10px;
      }
      
      .menu-trigger img {
        border-radius: 90px;
        width: 20px;
        height: 20px;
      }
      
      .menu {
        background: #ffffff;
        border-radius: 8px;
        position: absolute;
        top: 60px;
        right: 0;
        width: 200px;
        box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
        opacity: 0;
        visibility: hidden;
        transform: translateY(-20px);
        transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
      }
      
      .menu.active {
        opacity: 1;
        z-index: 9;
        visibility: visible;
        transform: translateY(0);
      }
      
      .menu ul {
        list-style: none;
        padding: 0;
        margin: 0;
      }
      
      .menu li {
        border-bottom: 1px solid #dddddd;
      }
      
      .menu li p {
        text-decoration: none;
        color: #333333;
        padding: 10px 15px ;
        cursor: pointer;
        margin-bottom: 0px;
        display: block;
    }
      .font-size{
        font-size: 13px;
      }
      [label] .tab-list {
        border-bottom: 1px solid #ccc;
        padding-left: 0;
      }
      
      .tab-list-item {
        display: inline-block;
        list-style: none;
        margin-bottom: -1px;
        padding: 0.5rem 0.75rem;
      }
      
      .tab-list-active {
        background-color: white;
        border: solid #ccc;
        border-width: 1px 1px 0 1px;
      }
      #firstcontent.active {
        display: block;
    }
    #firstcontent {
        display: none;
    }
    #secondcontent.active {
        display: block;
    }
    #secondcontent {
        display: none;
    }
    .single-pti-box-group label button {
        float: right;
        margin-top: -6px;
        /* min-height: 44px; */
    }
    
    .green-tick {
        font-size: 18px;
        color: green;
    }
    .package-table td, th {
        padding: 5px;
        border: 1px solid #cbcbcb;
    }
    .faq-inner .card .card-header {
        background-color: #fff;
        padding-left: 0;
    }
    
    #accordionExample .card .card-header{
        position: relative;
        padding: 7px;
        background: #8ba6d2;
        color: #fff;
    }
    #accordionExample .card .card-header h2 button{
        width: 100%;
        text-align: left;
        color: #fff;
    }
    .btn:focus, .btn.focus {
        outline: 0;
        box-shadow: unset!important;
    }
    .single-pti-modalbox-group {
    display: inline-flex;
    width: 100%;
    margin-bottom: 0rem!important;
    }
    .single-pti-modalbox-group label {
    width: 20%;
    margin-top: 0px;
    font-size: 13px;
    color: #8d8d8d;
    font-weight: 600;
    }
    .single-pti-modalbox-group .select-box2  {
    width: 40%!important;
    margin-right: 10px;
    // margin-bottom: 0px!important;
    }
    .single-pti-modalbox-group button {
    width: 30%;
    font-size:13px;
    
    }
    .accordion-btn {
        background: #8ba6d2;
        height: 48px;
        position: relative;
        box-shadow: none;
        color: #fff;
        width: 100%;
        border-color: #8ba6d2;
        text-align: left;
        border: none;
        padding-left: 18px;
        margin-top: 5px;
    }
    .accordion-btn i {
        position: absolute;
        right: 18px;
        top: 3px;
        width: 40px;
        font-size: 20px;
        height: 40px;
        line-height: 41px;
        color: #fff;
    
    }
    .accordion-body{
        padding: 20px;
    }
    
    .sign {
        border-radius: 50%;
        font-size: 15px;
        height: 15px;
        text-align: center;
        line-height: 15px;
        font-weight: 700;
        width: 15px;
        color: rgb(52, 52, 52);
        background: #fff;
    }
      .accordian-header {
        font-weight: 600;
        font-size: 18px;
        cursor: pointer;
        font-weight: 100;
        display: flex;
        color: #FFF;
        align-items: center;
        justify-content: space-between;
        padding: 10px 10px;
        border: 2px solid #8ba6d2;
        background-color: #8ba6d2;
      }
      .accordian-body {
        padding: 15px 5px;
      }
    
    
      .popup-box {
        position: fixed;
        background: rgb(51 115 221 / 52%);
        width: 100%;
        height: 100vh;
        top: 0;
        left: 0;
        z-index: 99;
      }
      .popup-box2 {
        position: fixed;
        background: rgb(51 115 221 / 52%);
        width: 100%;
        height: 100vh;
        top: 0;
        left: 0;
        z-index: 99;
      }
      .box {
        position: relative;
        width: 90%!important;
        margin: 0 auto;
        height: auto;
        margin-top: calc(10vh - 20px);
        background: #fff;
        border-radius: 4px;
        padding: 20px;
        border: 1px solid #999;
        overflow: auto;
      }
      
      .close-icon {
        content: 'x';
        cursor: pointer;
        display: none;
        position: fixed;
        right: calc(15% - 20px);
        top: calc(23vh - 35px);
        background: #fff;
        color: #3373dd;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        line-height: 20px;
        text-align: center;
        border: 1px solid #999;
        font-size: 15px;
      }
      .addbutton2 {
        max-height: 43px;
    }
    .pop-btn {
        font-size: 13px;
        line-height: 20px!important;
        height: 30px!important;
    }
    .table thead th {
        vertical-align: bottom;
        border: none!important;
        padding: 13px 6px;
    }
    .detail-view-flex{
        display: flex;
        width: 100%;
    }
    .detail-view-flex h5{
        width: 40%;
        font-size: 13px;
        font-weight: 600;
        margin-top: 5px;
        color: #656768;
    
    }
    .detail-view-flex p{
        width: 60%;
        padding: 4px;
        font-size: 13px;
        min-height: 30px;
        // height: 30px;
        border: 1px solid #007bff;
    }
    .input-flex .input-flex-input {
        width: 33%;
        margin-right: 34px;
    }
    .input-flex label {
        width: 15%;
        margin-top: 10px;
        color: #787878;
        font-size: 14px;
        font-weight: 600;
    }
    .item-dashboard .col-lg-12.col-md-12 {
        margin-top: 6px;
    }
    .input-flex {
        display: flex;
        width: 100%;
        margin-bottom: 0px;
        padding: 5px 30px;
    }
    .input-flex .input-flex-button {
        max-height: 44px;
        display: flex;
        line-height: 29px;
    }
    .label-header h6 {
        width: 35%;
        text-align: center;
        color: #787878;
        font-size: 14px;
        font-weight: 600;
    }
    .input-flex-input p{
        padding: 12px;
        min-height: 45px;
        border: 1px solid #007bff;
    }
    .pti-date-grid .col-sm-2 {
        width: 19%!important;
    }
    
    .label-header p {
        width: 15%;
    }
    .label-header {
        display: flex;
        width: 100%;
        margin-top: 15px;
    }
    .sidebar-menu-collapse:not(.show) .sidebar-menu-text, .sidebar-menu-collapse:not(.show) .sm-logo{
        display: block;
    }
    .sidebar-menu-dark .sm-logo{
        display: none;
    }
    .sm-logo img {
        height: 70px!important;
    }
    .ag-theme-alpine .ag-header{
        background: #3373dd;
        height: 40px!important;
        min-height: 40px!important;
    }
    span.ag-header-cell-text {
        color: #fff!important;
    }
    // .ag-center-cols-container {
    //     width: 100%!important;
    // }
    // .ag-header-row.ag-header-row-column {
    //     width: 100%!important;
    // }
    .ag-theme-alpine .ag-row-hover {
        background-color: #d7d7d7!important;
    }
    .ag-row-odd {
        background-color: #f4f4f4!important;
    }
    
    span.ag-icon.ag-icon-menu {
        color: #fff!important;
        display: none;
    }
    span.ag-header-icon.ag-header-label-icon.ag-sort-ascending-icon {
        color: #fff;
    }
    span.ag-header-icon.ag-header-label-icon.ag-sort-descending-icon{
        color: #fff;
    }
    .ag-cell.ag-cell-not-inline-editing.ag-cell-normal-height.ag-cell-value button {
        margin-right: 5px;
    }
    .ag-theme-alpine .ag-paging-panel{
        border: none;
        width: 100%;
    }
    .ag-paging-panel {
        justify-content: flex-start;
    }
    span.ag-paging-row-summary-panel {
        width: 50%;
    }
    .ag-paging-page-summary-panel{
        justify-content: flex-end;
        width: 50%;
    }
    .ag-theme-alpine .ag-root-wrapper {
         border: none; 
    }
    span.ag-icon.ag-icon-previous {
        background: #3373dd;
        color: #fff;
        padding: 6px;
    }
    span.ag-icon.ag-icon-next{
        background: #3373dd;
        color: #fff;
        padding: 6px;
    }
    .ag-header-icon.ag-header-label-icon.ag-filter-icon {
        color: #fff;
    }
    .ag-center-cols-container{
        width: 100%;
    }
    .ag-body-horizontal-scroll-viewport {
        overflow-x: scroll;
    }
    .input-flex-button {
        font-size: 13px;
    }
    .ag-theme-alpine .ag-header-icon{
        font-size: 12px;
    }
    .ag-theme-alpine .ag-icon{
        font-size: 13px!important;
    }
    .ag-theme-alpine .ag-cell, .ag-theme-alpine .ag-full-width-row .ag-cell-wrapper.ag-row-group{
        padding-left: 10px!important;
        padding-right: 0px!important;
    }
    .ag-theme-alpine .ag-header-cell, .ag-theme-alpine .ag-header-group-cell {
        padding-left: 10px;
        padding-right: 10px;
    }


    #toggle-slider .toggle {
        --width: 110px;
        --height: calc(var(--width) / 3);
        position: relative;
        display: inline-block;
        width: var(--width);
        height: var(--height);
        border-radius: var(--height);
        cursor: pointer;
      }
  
      #toggle-slider .toggle input {
        display: none;
      }
  
      #toggle-slider .toggle .slider {
        position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 33px;
    border-radius: var(--height);
    border: 2px solid #4184f3;
    transition: all 0.4s ease-in-out;
      }
  
      #toggle-slider .toggle .slider::before {
        content: "";
        position: absolute;
        top: 3px;
        left: 2px;
        width: calc(var(--height) * 0.6);
        height: calc(var(--height) * 0.6);
        border-radius: calc(var(--height) / 2);
        /* border: 3px solid #969696; */
        background-color: #4184f3;
        box-shadow: 0px 1px 3px rgb(0 0 0 / 30%);
        transition: all 0.4s ease-in-out;
      }
  
      #toggle-slider .toggle input:checked+.slider {
        background: #2196F3;
        color: #fff!important;
      }
  
      #toggle-slider .toggle input:checked+.slider::before {
        border-color: #ffffff;
    background-color: #ffffff;
    transform: translateX(calc(var(--width) - var(--height)));
      }
  
    #toggle-slider .toggle .labels {
     position: absolute;
    top: 5px;
    left: 0;
    width: 100%;
    height: 100%;
    margin-left: 6px;
    color: #ffffff;
    font-size: 12px;
    font-family: sans-serif;
    transition: all 0.4s ease-in-out;
      }
  
      #toggle-slider .toggle .labels::after {
        content: attr(data-off);
        position: absolute;
        right: 14px;
        top: 3px;
        color: #4184f3;
        opacity: 1;
        transition: all 0.4s ease-in-out;
      }
  
      #toggle-slider .toggle .labels::before {
        content: attr(data-on);
        position: absolute;
        left: 8px;
        opacity: 0;
        top: 3px;
        text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.4);
        transition: all 0.4s ease-in-out;
      }
  
      #toggle-slider .toggle input:checked~.labels::after {
        opacity: 0;
      }
  
      #toggle-slider .toggle input:checked~.labels::before {
        opacity: 1;
      }
      #toggle-slider {
        display: flex;
    }
    .example-header label {
        float: right;
    }
   
      .single-pti-box-export label {
        width: 50%;
        color: #787878;
        font-size: 14px;
        font-weight: 600;
        margin-top: 10px;
    }
    .textarea-box {
        border-color: #3373dd;
        padding: 10px;
        border-radius: 12px;
    }
    .textarea-box {
        border-color: #3373dd;
        padding: 10px;
        border-radius: 12px;
    }
    .table-search{
        position: relative;
    }
    .table-search i {
        position: absolute;
        right: 1px;
        font-size: 12px;
        top: 0px;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
        padding: 11px;
        background: #3373dd;
        color: #fff;
    }
    .table-search ::placeholder {
        color: rgb(255, 255, 255);
        opacity: 1; /* Firefox */
      }
      .ag-header-row.ag-header-row-column {
        height: 40px!important;
    }
     
      .table-search input {
        padding: 0px 11px;
        font-size: 13px;
        height: 35px!important;
        background: #fff;
        border: 1px solid #3373dd;
        border-radius: 6px;
        max-width: 180px;
    }
    .search-add {
        float: right;
        display: flex;
    }
    .sorting-num select {
        height: 35px;
        font-size: 13px;
        border-radius: 5px;
        color: #3373dd;
        min-height: 30px;
        border: 1px solid #3373dd;
    }
 
    @media (min-width:768px) and (max-width:1200px){
        .single-filter {
            max-width: 30%!important;
            flex: 30%!important;
            padding: 4px;
        }


    }
    button.active{
        background: red;
    }
    .add-document-box {
        border: 1px solid #4184f3;
        padding: 25px;
        background: #4184f312;
    }
    .document-group{
        width: 100%;
    }
    .add-document{
        margin: 1% 0px;
    }
    // .login-bg{
    //     background-image: url(http://localhost:3000/static/media/login-page-banner.dc6f8a05f2081633ecd7.png);
    //     background-size: cover;
    //     background-repeat: no-repeat;
    //     background-position: center;
    // }
    .login-card{
        min-height: auto!important;
        margin-top: 4%!important;
    }
    @media (min-width:768px) and (max-width:900px){
        // .login-bg{
        //     display: none;
        // }

    }
    #firstcontent {
    transition: all 0.4s ease-in-out;
    }
    #secondcontent {
        transition: all 0.4s ease-in-out;
    }
    .threeDots {
        display: flex;
        width: 100%;
        position: relative;
    }
    .threeDots input {
        width: 100%!important;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
    }
    .threeDots button {
        width: 50px;
        border: none!important;
        background:#8ba6d2;
        color: #fff;
        height: auto;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
    }
    .threeDots button:hover {
        background:#3373dd;
    }
    .input-flex .input-flex-button i {
        line-height: 30px;
        margin-left: 5px;
    }
    .btn-primary {
        background: #3373dd!important;
    }
    .ag-body-viewport-wrapper.ag-layout-normal {
        overflow-x: scroll;
        overflow-y: scroll;
      }
      ::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 8px;
        height: 8px;
      }
      ::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: #849dc6;
        box-shadow: 0 0 1px rgba(255,255,255,.5);
      }
      .ag-center-cols-viewport {
        width: 100%;
        overflow-x: auto;
    }
    // .ag-center-cols-container {
    //     width: 100%!important;
    // }
    .error{
        text-align: center;
        padding: 18%;
        background: #76b8ee;
    }
    .err-logo img {
        height: 80px;
    }
    .err-login{
        color: #007bff;
    }
    .err-main{
        background: #76b8ee;
    }
    .confirm-box__content {
        z-index: 300;
        background-color: #ffffff;
        box-shadow: 0 4px 8px 0 rgb(0 0 0 / 29%);
        padding: 2em;
        border-radius: 5px;
        width: 420px!important;
        min-height: 147px!important;
        max-width: 420px!important;
        text-align: center;
    }
    .confirm-box {
        z-index: 1000;
        position: absolute;
        left: 40%;
        top: 40%;
        width: 242px!important;
    }
    .confirm-box__actions {
        display: flex;
        padding-top: 2em!important;
        justify-content: center!important;
    }
    .confirm-box__actions button {
        background: #2e67c6;
        color: #fff;
        padding: 5px 20px;
        border: none;
    }
    option{
        text-transform: capitalize;
    }
    .width-full{
        width: 100%;
    }
    .width-full .input-box{
        width: 70%;
    }
    .width-full label{
        width: 30%;
    }
    .width-full .select-box
    {
        width: 70%;
    }
    .itc-popup-search label {
        width: 100%!important;
    }
  
    .popup-box2 {
        position: fixed;
        background: rgba(51, 115, 221, 0.52);
        width: 100%;
        height: 100vh;
        top: 0;
        left: 0;
        z-index: 99;
    }
    .box2 {
        position: relative;
        width: 80% !important;
        margin: 0 auto;
        height: auto;
        margin-top: calc(5vh - 10px);
        background: #fff;
        border-radius: 4px;
        padding: 20px;
        border: 1px solid #999;
        overflow: auto;
    }
    .qty-flex {
        display: flex;
    }
    .qty-flex label {
        width: 65%;
        text-align: right;
        margin: 5px;
        font-size: 13px;
    }
    .certi-view-icon {
        position: absolute;
        right: 15px;
        top: 35%;
        font-size: 20px;
    }
    .certi-view {
        position: relative;
    }
    .width-full .date-box {
        width: 70%;
    }
    .pti-btn {
        top: 5px;
        position: absolute;
        right: 2%;
        border: none;
        border-radius: 16px;
        width: 180px;
        line-height: 26px;
        height: 25px;
        font-size: 13px;
        box-shadow: 0 9px 20px 0 rgb(0 0 0 / 3%), 0 6px 23px 0 rgb(0 0 0 / 4%);
    }
    .filter-select-box select {
        color: #0563af;
        padding: 5px;
        height: 35px;
        width: 100%;
        border: 1px solid #4184f3;
        font-size: 12px;
        appearance: button;
        border-radius: 5px;
    }
    .filter-select-box {
        position: relative;
        width: 100%;
        margin-bottom: 15px;
            min-width: 220px;
    }
    // .filter-select-box::before {
    //     content: "\f107";
    //     font-family: FontAwesome;
    //     position: absolute;
    //     border-radius: 0px 5px 5px 0px;
    //     top: 0;
    //     right: 0;
    //     width: 45px;
    //     height: 100%;
    //     text-align: center;
    //     font-size: 20px;
    //     line-height: 45px;
    //     color: #fff;
    //     background-color: #3373dd;
    //     pointer-events: none;
    // }
    .item-btn:hover{
        background: transparent;
        border:none;
    }
    .react-toggle .toggle .labels{
        top:-7px!important;
    }
    .react-toggle .toggle {
        width: 100px!important;
    }    
    .ag-theme-alpine .ag-row {
        font-size: 13px;
    }
    .ag-header-cell-sortable {
        font-size: 12px;
    }
    .w-40 {
        width: 40%!important;
    }
    .w-15 {
        width: 15%!important;
    }
    .w-85 {
        width: 85%!important;
    }
    .availableSpan {
        position: absolute;
        top: 0px;
        font-size: 12px;
        right: 9%;
    }
    .avilableView {
        position: relative;
    }
    .item-avail {
        position: relative;
    }
    .ag-body {
        position: relative !important;
        top: auto !important;
        height: auto !important;
    }
    ::placeholder {
        color: #3373dd!important;
  }
  .textarea-color {
    background: #e4e4e4;
    margin: 5px;
    width: 85%;
    overflow: hidden;
    height: 32px;
}
.input-size input{
    font-size: 13px;
}
.action-buttons {
    padding: 0px;
    margin: 0px;
}
.action-buttons i {
    font-size: 14px;
}
.action-buttons button {
    padding: 2px 7px;
}
td{
    font-size: 13px;
}

  .pagination{
    float: right;
    background-color: blue!important;
  }
  .pagination button{
    float: right;
    background-color: blue!important;
  }
  .react-bootstrap-table-pagination-total {
    margin-left: 20px;
}
.page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: #4184f3!important;
    border-color: #4184f3!important;
}
// .page-link{
//    color: #4184f3!important; 
// }
.serial-no{
    width: 100%;
}
.accordian-header{
font-size: 14px;
padding: 5px;
}
.history-table{
    width: 100%;
}
.history-table th{
    font-size: 13px;
    font-weight: 100;
}
.history-data th{
    font-weight: 600;
    font-size: 14px;
    background: #f2f2f2;
}

.text-warning {
    font-size: 12px;
    color: #fb2727!important;
}
#boe-table .input-size input {
    text-align: center;
}
#boe-table .ag-cell {
    border-right-width: 1px;
    text-align: center;
}
#boe-table .ag-header-cell-label {
    display: flow-root!important;
    flex: 1 1 auto;
    text-align: center;
    overflow: hidden;
    margin: auto!important;
    align-items: center;
    text-overflow: ellipsis;
    align-self: stretch;
}
.ag-selection-checkbox.ag-invisible {
    display: none !important;
}
.password-label {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 8px;
    color: #444;
  }
  
  #newPassword, #confirmPassword{
    height: 40px;
    min-width: 100%;
    border-radius: 4px;
    border: 1px solid #ccc;
    padding: 1rem;
    font-size: 16px;
    outline: none;
    transition: all 0.2s ease-in-out;
    margin-bottom: 1rem;
  }
  
  #newPassword, #confirmPassword:focus {
    border-color: #0077cc;
    box-shadow: 0 0 0 2px rgba(0, 119, 204, 0.2);
  }
  .updatepwd-btn {
    background-color: #0077cc;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 6px 12px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    margin: 1rem;
    float: right;
  }
  
  .updatepwd-btn:hover {
    background-color: #005ea6;
  }
  .mooo{
    width: 50%;
  }

  //zenxl responsive  

@media screen and (min-width: 320px) and (max-width: 520px) {
    .sidebar-menu-collapse.collapse {
        display: block;
        transition: 2s;
    }
    .sidebar-menu-collapse.show {
        display: none;
    }
    .ag-theme-alpine .ag-paging-panel > * {
        margin: 0px 0px;
    }
span.ag-paging-row-summary-panel {
    text-align: left;
}
    .datePicker-0-2-11 .MuiTextField-root {
        margin-left: 5px;
    }
    .css-1xhypcz-MuiStack-root {
        display: -webkit-inline-box!important;
    }
    .login-container {
        padding: 10px;
    }
    .login-card {
        padding: 40px 20px;
    }
    .css-1s50f5r {
        flex-basis: 100%!important;
        max-width: 100%!important;
    }
   
    .datePicker-0-2-12 .MuiTextField-root {
        width: 150px!important;
        margin-left: 5px;
    }
    .css-1xhypcz-MuiStack-root>:not(style)+:not(style) {
        margin-top: 0px !important;
    }
    .css-1osj8n2-MuiGrid-root{
        min-width: 100%!important;
    }
    .certi-popup {
        height: auto; 
    }
    .certi-popup embed {
        height: auto; 
    }
    .login-bg{
display: none;
    }
    .ag-theme-alpine .ag-paging-panel > * {
        margin: 0 4px;
    }
    .width-full .date-box {
        width: 100%;
    }
    .date-box input {
        width: 100%;
    }
    .detail-view-flex {
        display: block;
    }
    .detail-view-flex h5, .detail-view-flex .h5 {
        width: 100%;
    }
    .detail-view-flex p {
        width: 100%;
    }
    .pti-btn {
        right: 5%;
        width: 135px;
        line-height: 24px;
        height: 25px;
        font-size: 12px;
    }
    .single-pti-box-header {
        padding: 7px 10px;
        text-align: left;
    }
    
    
    .btn-footer button {
        margin-right: 5px;
        font-size: 12px;
        padding: 6px;
    }
    .pti-search-add {
        display: contents!important;
    }
    .ag-paging-page-summary-panel {
        width: 60%;
    }
    span.ag-paging-row-summary-panel {
        width: 40%;
    }
    .ag-theme-alpine .ag-paging-button, .ag-theme-alpine .ag-paging-description {
        margin: 0 2px;
    }
    .w-50 {
        width: 100% !important;
    }
    .single-pti-box-group {
        display: block;
    }
    .width-full .select-box {
        width: 100%;
    }
    .width-full label {
        width: 100%;
    }
    .width-full .input-box {
        width: 100%;
    }
    .single-filter {
        max-width: unset !important; 
        flex: auto !important; 
        padding: 4px;
    }
    .sidebar-menu-nav-title {
        display: block!important;
    }
    .main-wrapper {
        display: unset;
    }
    .sidebar-menu {
       flex-direction: unset;
    }

    .sidebar-menu-dark .sidebar-menu-nav .sidebar-menu-nav-link, .sidebar-menu-dark .sidebar-menu-nav .sidebar-menu-sub-toggle {
        display: flex;
    }
    .sidebar-menu-footer {
        position: relative;
        width: 100%;
    }
    .sorting-num {
        font-size: 0px;
    }
    // .search-add {
    //     display: contents!important;
    // }
    .same-active {
        border: 1px solid #4184f3;
        min-width: 120px!important;
        text-align: center;
        margin-left: 5px!important;
        padding: 5px;
    }
    .input-box input {
        padding: 5px 5px!important;
    }
    .custom-control-label {
        padding-left: 30px;
    }

    }
    @media (min-width:768px) and (max-width:1024px){
        .recharts-wrapper {
            margin: auto;
        }
        .datePicker-0-2-11 .MuiTextField-root {
            margin-left: 5px;
        }
        .css-1xhypcz-MuiStack-root {
            display: -webkit-inline-box!important;
        }
        .datePicker-0-2-12 .MuiTextField-root {
            width: 150px!important;
            margin-left: 5px;
        }
        .css-1xhypcz-MuiStack-root>:not(style)+:not(style) {
            margin-top: 0px !important;
        }
        .css-1osj8n2-MuiGrid-root{
            min-width: 100%;
        }
        .css-1osj8n2-MuiGrid-root{
            min-width: 100%;
        }
    .single-filter {
        max-width: unset !important; 
        flex: auto !important; 
        padding: 4px;
    }
    .login-bg {
        display: none;
    }
    .login-container {
        padding: 10px;
    }
    .add-document-box {
        padding: 5px;
    }
    .ag-theme-alpine .ag-ltr .ag-header-select-all {
        margin-right: 18px;
    }
    .btn-eor button {
        margin-top: 0px!important;
        margin-bottom: 15px;
    }
    .width-full .date-box {
        width: 100%;
    }
    .width-full .select-box {
        width: 100%;
    }
    .width-full label {
        width: 100%;
    }
    .width-full .input-box {
        width: 100%;
    }
    .single-pti-box-group {
        display: block;
    }
    .detail-view-flex h5, .detail-view-flex .h5 {
        width: 100%;
    }
    .btnooter button {
        font-size: 13px;
    }
    .pti-example-header label{
        float: left !important;
    }
    .pti-search-add {
        display: block!important;
    }
    span.ag-icon.ag-icon-next {
        padding: 3px;
    }
    .search-add {
        float: left;
    }
    .detail-view-flex p {
    width: 100%;
    }
    .detail-view-flex {
        display: block;
    }
    .pti-btn {
        position: unset; 
        width: 135px;
        line-height: 24px;
        margin: -2px 5px;
        float: right;
        height: 25px;
    }
    .btn-footer button {
        margin-top: 15px;
    padding: 6px 6px;
    font-size: 13px;
    margin-right: 5px;
    }
    .document-btn button{
        padding: 0px!important;
        margin-top: 5px!important;
    }
    .btnooter {
        margin-top: 10px;
        float: left;
    }
    .filter-select-box {
        min-width: 270px;
    }
    .search-add {
        // display: contents;
    }
    .sorting-num {
        font-size: 0px;
    }
    .ag-theme-alpine .ag-paging-panel > * {
        margin: 0 2px; 
    }
    .ag-paging-page-summary-panel {
        justify-content: flex-end;
        width: auto; 
        width: 70%!important;
    }
    span.ag-paging-row-summary-panel {
        width: 30%;
    }
    span.ag-icon.ag-icon-previous {
        padding: 3px;
    }
    .w-50 {
        width: 100% !important;
    }
    .confirm-box {
        position: absolute;
        left: 0%;
        top: 10%;
        width: 100%!important;
    }
    .confirm-box__content {
        width:100% !important;
        min-height: 100% !important;
        max-width: 100% !important;
    }
    .box2 {
        width: 100% !important;
    }
    .main-wrapper {
        display: block;
    }    
    .sidebar-menu-collapse {
        display: none;
        width: 100%;
     }
 
     .sidebar-menu-collapse.show {
         display: block;
         width: 100%;
     }
     .main-wrapper {
         display: block;
     }  
     .collapse:not(.show) {
         display: none;
     }
     .sidebar-menu-collapse:not(.show) {
         width: 100%;
     }
     .box {
        width: 98% !important;
        margin-top: calc(4vh - 20px);
        padding: 10px;
    }
    .pti-fullwidth-btn .w-50 {
        width: 50%!important;
    }
    .pti-fullwidth-btn .btn-footer button {
        margin-top: 10px!important;
    }
    .pti-fullwidth-btn .btnooter {
         float: right!important; 
    }
    .login-container {
        padding: 10px;
    }    

}


@media screen and (min-width: 540px) and (max-width: 720px) {
    .login-bg {
        display: none;
    }
    .filter-select-box {
        min-width: 120px;
    }
    .sorting-num {
        font-size: 0px;
    }
    .login-bg {
        display: none;
    }
    .sidebar-menu-collapse {
        display: none;
        width: 100%;
     }
 
     .sidebar-menu-collapse.show {
         display: block;
         width: 100%;
     }
     .main-wrapper {
         display: block;
     }  
     .collapse:not(.show) {
         display: none;
     }
     .sidebar-menu-collapse:not(.show) {
         width: 100%;
     }
     .search-add {
        float: left;
    }
    .confirm-box {
        left: 10%;
        top: 20%;
    }
    span.ag-paging-row-summary-panel {
        width: 30%;
    }
    .ag-paging-page-summary-panel {
        justify-content: flex-end;
        width: 70% !important;
    }
    .ag-theme-alpine .ag-paging-button, .ag-theme-alpine .ag-paging-description {
        margin: 0 3px;
    }
    .box {
        width: 98% !important;
        margin-top: calc(4vh - 20px);
        padding: 10px;
    }


}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .w-50 {
        width: 100% !important;
    }
    .width-full .select-box {
        width: 100%;
    }
    .width-full .input-box {
        width: 100%;
    }
    .single-pti-box-group{
        display: block;
    }
    .width-full label {
        width: 100%;
    }
    .sorting-num {
        font-size: 0px;
    }
    .login-bg {
        display: none;
    }
    .sidebar-menu-collapse {
       display: none;
       width: 100%;
    }

    .sidebar-menu-collapse.show {
        display: block;
        width: 100%;
    }
    .main-wrapper {
        display: block;
    }  
    .collapse:not(.show) {
        display: none;
    }
    .sidebar-menu-collapse:not(.show) {
        width: 100%;
    }
    // .collapse:not(.show) {
    //     display: none;
    // }
    // .sidebar-menu-collapse.collapse {
    //     display: none;
    // }
    // .search-add {
    //     float: left;
    // }
    .sidebar-menu.sidebar-menu-expand-sm.show {
        display: block!important;
    }
    
    .sidebar-menu.sidebar-menu-expand-sm {
        display: none!important;
    }
    .confirm-box {
        left: 28%;
        top: 10%;
    }
    .sorting-num {
        font-size: 0px;
    }
    // .search-add {
    //     float: left;
    // }

}

.recharts-surface {
    width: 100%!important;
}
.recharts-legend-wrapper {
    width: 100%!important;
}
rounded.MuiPaper-elevation1.css-1q7ecze-MuiPaper-root {
    padding: 10px!important;
}
.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
    height: 1em;
    padding: 12.5px 6px!important;
    font-size: 13px!important;
}
.datePicker-0-2-18 .MuiTextField-root {
    height: 60px!important; 
}
.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root{
    font-size: 13px!important;
}
.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input{
    padding: 8.5px 12px!important;
}
.css-1q7ecze-MuiPaper-root{
    padding: 15px!important;
}
.datePicker-0-2-8 .MuiTextField-root{
    height: 60px!important; 
}
.datePicker-0-2-7 .MuiTextField-root {
    height: 60px!important;
}
.datePicker-0-2-9 .MuiTextField-root{
    height: 60px!important;
}

.datePicker-0-2-10 .MuiTextField-root {
    height: 60px!important;
}
.datePicker-0-2-11 .MuiTextField-root{
    height: 60px!important;
}
.datePicker-0-2-12 .MuiTextField-root{
    height: 60px!important;
}

.certi-popup{
    height: 350px;
    overflow-y: auto;
}

.pagination_pti {
    border: none;
    width: 100%;
    height: 48px;
    box-sizing: border-box;
    outline: none;
    justify-content: flex-start;
    align-items: center;
    display: flex;
    font-size: 13px;
    line-height: normal;
    margin: 0 18px;
}

.pagin_left {
    width: 50%;
}

.pagin_left_text {
    font-weight: 700;
}

.pagin_right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 50%;
    margin-right: 2rem;

}

.pagination-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 3px;
}

.pagination-container button {
    border: none;
    background: none;
    cursor: pointer;
    font-size: 13px;
    color: #333;
    margin: 0 4px;
}

.pagination-container button[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
}

.pagination-container .prev-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    padding: 8px;
    background-color: #0072c6;
    color: #fff;
}

.pagination-container .prev-btn i {
    font-size: 13px;
}

.pagination-container .next-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    padding: 8px;
    background-color: #0072c6;
    color: #fff;
}

.pagination-container .next-btn i {
    font-size: 13px;
}

.pagination-container .first-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
}

.pagination-container .first-btn i {
    font-size: 13px;
}

.pagination-container .last-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
}

.pagination-container .last-btn i {
    font-size: 13px;
}

.pagin_pageno {
    margin: 0 0.5rem;
}